<template lang='pug'>
.user-info
  .user-info__cont.cont
    form.user-info__form(@input="changeForm", @submit.prevent="sendForm")
      .user-info__input-box
        label.user-info__label.text(for="name") Имя и фамилия
        input.user-info__input.input.text(type="text", id="name", v-model="name", autocomplete="off")
      .user-info__input-box
        label.user-info__label.text(for="email") Электронная почта
        input.user-info__input.input.text(type="email", id="email", v-model="email", autocomplete="off")
      .user-info__input-box
        label.user-info__label.text(for="phone") Телефон
        input.user-info__input.input.text(type="tel", id="phone", v-model="phone", autocomplete="off")
      .user-info__input-box
        label.user-info__label.text(for="password") Пароль
        button.user-info__change.link-dot_white.text(v-if="isHiddenPassword", type="button", @click="showPasswordField") Изменить пароль
        input.user-info__input.user-info__input_password.input.text(
             v-if="!isHiddenPassword",
            type="password",
            id="password",
            minlength="8",
            :class="{'input-invalid' : !isValidPassword}",
            v-model="password",
            required=true,
            autocomplete="off"
          )
        span.user-info__text.text(v-if="!isHiddenPassword") Минимум — 8 символов
      .user-info__button-box
        button.user-info__save.button-white(:disabled="isDisabled") Сохранить
        button.user-info__cancel.button-dark(v-if="!isDisabled", type="button", @click="cleanForm()") Отменить изменения
      p.user-info__message.text(v-if="isShowMessage", :class="isSuccsess ? 'user-info__message_succsess' : 'user-info__message_error'") {{messageText}}
</template>

<script>
import '@/assets/styles/components/user-info.sass'
import { getUser, updateUser } from '../../assets/scripts/API'

export default {
  name: 'TheUserInfo',
  data () {
    return {
      user: null,
      name: '',
      email: '',
      phone: '',
      password: '',
      isDisabled: true,
      isHiddenPassword: true,
      isValidPassword: false,
      isSuccsess: false
    }
  },
  computed: {
    messageText: function () {
      return this.isSuccsess ? 'Ваши данные успешно сохранены' : 'Данные не сохранились. Попробуйте еще раз'
    }
  },
  methods: {
    changeForm () {
      if (this.name !== this.user.name) this.isDisabled = false
      else if (this.email !== this.user.email) this.isDisabled = false
      else if (this.phone !== this.user.phone) this.isDisabled = false
      else if (this.password.length > 7) {
        this.isDisabled = false
        this.isValidPassword = true
      } else {
        this.isDisabled = true
        this.isValidPassword = false
      }
    },
    showPasswordField () {
      this.isHiddenPassword = false
    },
    async sendForm () {
      const data = await updateUser(this.email, this.password, this.name, this.phone)
      this.isShowMessage = true
      this.isSuccsess = data.status === 'success'
      if (this.isSuccsess) {
        this.user = data.user
        this.cleanForm(true)
      }
    },
    cleanForm (isShowMessage = false) {
      this.name = this.user.name
      this.email = this.user.email
      this.phone = this.user.phone
      this.password = ''
      this.isDisabled = true
      this.isShowMessage = isShowMessage
    }
  },
  async mounted () {
    this.user = await getUser()
    this.cleanForm()
  }
}
</script>
