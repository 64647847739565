<template lang="pug">
head-meta(title-page="Личные данные" )
user-menu(block-name="Личные данные")
the-personal-info
</template>

<script>
import UserMenu from '../../components/_user/UserMenu'
import ThePersonalInfo from '../../components/user-info/TheUserInfo'
import HeadMeta from '../../components/_layout/HeadMeta'

export default {
  name: 'UserInfoPage',
  components: { HeadMeta, UserMenu, ThePersonalInfo }
}
</script>
